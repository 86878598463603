<template>
    <v-chip-group
        v-model="select"
        @change="update"
        :column="column"
        :mandatory="mandatory"
        :multiple="multiple"
        :small="!xSmall"
        :x-small="xSmall">
        <v-chip
            v-for="(item, index) in filteredItems"
            :activeClass="activeClass"
            :disabled="!isEditing"
            :filter="showTick"
            pill
            :small="!xSmall"
            :x-small="xSmall"
            :key="index">
            {{ nestVal(item, itemText, textFilter) }}
        </v-chip>
    </v-chip-group>
</template>

<script>
    export default {
        name: 'BT-Chips',
        data: function() {
            return {
                select: []
            }
        },
        props: {
            activeClass: {
                type: String,
                default: 'primary'
            },
            column: {
                type: Boolean,
                default: false
            },
            isEditing: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: null
            },
            itemText: {
                type: String,
                default: null
            },
            itemValue: {
                type: String,
                default: null
            },
            mandatory: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            onFilter: {
                type: Function,
                default: null
            },
            returnCSV: {
                type: Boolean,
                default: false
            },
            returnIndex: {
                type: Boolean,
                default: false
            },
            returnObject: {
                type: Boolean,
                default: false
            },
            showTick: {
                type: Boolean,
                default: false
            },
            suffix: {
                type: String,
                default: null
            },
            textFilter: {
                type: String,
                default: null
            },
            value: null,
            xSmall: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.load();
        },
        watch: {
            value: function(val) {
                if (val != this.select) {
                    this.load();
                }
            },
        },
        computed: {
            filteredItems() {
                return this.onFilter ? this.onFilter(this.items) : this.items;
            }
        },
        methods: {
            load() {
                if (this.value == null) {
                    return;
                }

                var d = this.returnCSV ? this.value.split(',') : this.value;
                var rList = [];
                for (let i = 0; i < this.items.length; i++) {
                    if (this.isLengthyArray(d)) {
                        if (this.itemValue != null) {
                            if (d.some(x => x == this.getNestedValue(this.items[i], this.itemValue))) {
                                rList.push(i);
                            }
                        }
                        else {
                            if (d.some(x => x == this.filteredItems[i])) {
                                rList.push(i);
                            }
                        }
                        
                    }
                    else if (d != null && !this.multiple) {
                        if (this.itemValue != null) {
                            if (this.getNestedValue(d, this.itemValue) == this.getNestedValue(this.items[i], this.itemValue)) {
                                rList.push(i);
                            }
                        }
                        else {
                            if (d == this.items[i]) {
                                rList.push(i);
                            }
                        }
                    }
                }
                
                if (this.multiple) {
                    this.select = rList;
                }
                else {
                    this.select = rList.length > 0 ? rList[0] : null;
                }
            },
            nestVal(item, path, filter) {
                var t = item;

                if (path != null) {
                    t = this.getNestedValue(item, path);
                }
                
                if (filter != null) {
                    return this.$options.filters[filter](t);
                }
                else {
                    return t;
                }
            },
            update() {
                if (this.select == null) {
                    this.$emit('input', null);
                    this.$emit('change', null);
                }
                else {
                    var v = this.select;

                    if (this.multiple) {
                        if (this.returnIndex) {
                            v = this.select;
                        }
                        else {
                            var selectedItems = [];
                            for (let i = 0; i < this.select.length; i++) {
                                selectedItems.push(this.filteredItems[this.select[i]]);
                            }

                            if (this.returnObject) {
                                v = selectedItems;
                            }
                            else {
                                v = this.itemValue != null ? selectedItems.map(x => x[this.itemValue]) : selectedItems;
                            }
                            
                            if (this.returnCSV) {
                                v = v.toString();
                            }
                        }
                    }
                    else {
                        if (this.returnIndex) {
                            v = this.select;
                        }
                        else if (this.select != null) {
                            var a = this.filteredItems[this.select];
                            if (a != null) {
                                if (this.returnObject) {
                                    v = a;
                                }
                                else {
                                    v = this.itemValue != null ? this.getNestedValue(a, this.itemValue) : a;
                                }
                            }
                        }
                    }

                    this.$emit('input', v);
                    this.$emit('change', v);
                }
            }
        }
    }
</script>